import banner from './../../../assets/Banner_image.png';
// import logo from './Logotype.svg';
import React, { useState, useEffect } from 'react';
import './styles.scss'
import DialogBox from './../../../shared-components/DialogBox'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HD from './../../../assets/spetrol-1.8.mov';
import whatsappLogo from '../../../assets/whatsapp.png';

toast.configure()

function Section1() {

  const listenScrollEvent = e => { };
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
    return () => {

    }
  }, [])
  const [openDialog, setOpenDialog] = useState(false)
  const openDialogBox = () => {
    setOpenDialog(true)
  }
  const openWhatsappChat = () => {
    window.open(`https://api.whatsapp.com/send?phone=919392201659`, '_blank');
  };

  return (
    <div className="background-container">
      <video autoPlay={true} poster={banner} id="videobg" loop={true} muted>
        <source src={HD} type="video/mp4" />
          Your browser does not support the video tag. I suggest you upgrade your
          browser.
        </video>
      <div className="content">
        <div className="description-video">
          <button type="button" className="notified_btn" onClick={() => openDialogBox()}>Download App</button>
        </div>
      </div>

      <div className="chat-whatsapp">
        <img src={whatsappLogo} alt="whatsapp" width="40" onClick={() => openWhatsappChat()} />
      </div>

      {openDialog && <DialogBox openDialog={openDialog} setOpenDialog={setOpenDialog} />}
    </div>


  );
}

export default Section1;
