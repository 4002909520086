import { useEffect } from 'react';

import './App.css';
import Navbar from './components/NavBar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home/home';
import Footer from './components/Footer';
import About from './pages/About'
import Contact from './pages/Contact'
import Terms from './pages/terms';
import PrivacyPolicy from './pages/privacy-policy';
import BlogsPage from './pages/Blogs';
import ScrollToTop from './shared-components/ScrollToTop';

const DownloadApp = () => {
  let message = '';
  useEffect(() => {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.spetrol_customer';
    }
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      // window.location.href = 'http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8';
      message = 'Sorry, currently our app is not available on google playstore. Stay tuned to get iOS app.';
    }
  }, []);
  return <h1>{message}</h1>;
};


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/about' exact component={About} />
        <Route path='/blogs' exact component={BlogsPage} />
        <Route path='/contactus' exact component={Contact} />
        <Route path='/terms' exact component={Terms} />
        <Route path='/policy' exact component={PrivacyPolicy} />
        <Route path='/download-app' exact component={DownloadApp} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
