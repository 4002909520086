import React from 'react';
import { Parser } from 'html-to-react'

const PrivacyPolicy = () => {
    const policy = `<style type="text/css">
    p.p1 {
        margin: 0.0px 0.0px 10.0px 0.0px;
        text-align: center;
        font: 11.0px 'Inter';
        color: #000000
    }

    p.p2 {
        margin: 0.0px 0.0px 10.0px 0.0px;
        text-align: justify;
        font: 11.0px 'Inter';
        color: #000000
    }

    p.p3 {
        margin: 0.0px 0.0px 10.0px 0.0px;
        text-align: justify;
        font: 11.0px 'Inter';
        color: #000000;
        min-height: 13.0px
    }

    p.p4 {
        margin: 0.0px 0.0px 7.3px 0.0px;
        text-align: justify;
        font: 11.0px 'Inter';
        color: #000000;
        background-color: #ffffff
    }

    p.p5 {
        margin: 0.0px 0.0px 10.0px 0.0px;
        font: 10.5px Times;
        color: #000000;
        background-color: #ffffff
    }

    p.p6 {
        margin: 0.0px 0.0px 10.0px 18.0px;
        text-align: justify;
        font: 11.0px 'Inter';
        color: #000000;
        min-height: 13.0px
    }

    p.p8 {
        margin: 0.0px 0.0px 10.0px 36.0px;
        text-align: justify;
        font: 11.0px 'Inter';
        color: #000000;
        min-height: 13.0px
    }

    p.p9 {
        margin: 0.0px 0.0px 10.0px 18.0px;
        text-align: justify;
        font: 11.0px 'Inter';
        color: #000000
    }

    p.p10 {
        margin: 0.0px 0.0px 0.0px 0.0px;
        text-align: justify;
        font: 11.0px 'Inter';
        color: #000000
    }

    p.p11 {
        margin: 0.0px 0.0px 0.0px 0.0px;
        text-align: justify;
        font: 11.0px 'Inter';
        color: #000000;
        min-height: 13.0px
    }

    p.p12 {
        margin: 0.0px 0.0px 0.0px 0.0px;
        font: 11.0px Helvetica;
        color: #000000;
        min-height: 13.0px
    }

    p.p13 {
        margin: 0.0px 0.0px 10.0px 32.2px;
        text-align: justify;
        font: 11.0px 'Inter';
        color: #000000;
        min-height: 13.0px
    }

    p.p14 {
        margin: 0.0px 0.0px 10.0px 32.2px;
        text-align: justify;
        font: 11.0px 'Inter';
        color: #000000
    }

    li.li2 {
        margin: 0.0px 0.0px 10.0px 0.0px;
        text-align: justify;
        font: 11.0px 'Inter';
        color: #000000
    }

    li.li7 {
        margin: 0.0px 0.0px 10.0px 0.0px;
        text-align: justify;
        font: 10.5px Times;
        color: #000000
    }

    span.s1 {
        text-decoration: underline
    }

    span.s2 {
        background-color: #ffff0b
    }

    span.s3 {
        font: 11.0px 'Inter'
    }

    span.s4 {
        background-color: #ffffff
    }

    table.t1 {
        border-collapse: collapse
    }

    td.td1 {
        width: 206.0px;
        height: 91.0px;
        border-style: solid;
        border-width: 1.0px 1.0px 1.0px 1.0px;
        border-color: #000000 #000000 #000000 #000000;
        padding: 4.0px 4.0px 4.0px 4.0px
    }

    ol.ol1 {
        list-style-type: lower-alpha
    }

    ol.ol2 {
        list-style-type: lower-roman
    }

    ol.ol3 {
        list-style-type: decimal
    }
</style>
<p class="p1"><span class="s1"><strong>PRIVACY POLICY</strong></span></p>
<p class="p2"><strong>Last updated: 31st Jan, 2022</strong></p>
<p class="p3"><br></p>
<p class="p2">Spetrol recognises the importance of maintaining the privacy of the information belonging to you. This Policy sets out details of how we treat user information we collect on <span class="s2">www.spetrol.in (the &ldquo;Website&rdquo;) and the mobile application Spetrol customer (the &ldquo;Application&rdquo;</span>). The Website/Application is owned and operated by M/s RAAMS MOVING TECHNOLOGIES PRIVATE LIMITED, a company incorporated under the Companies Act, 2013 and having its registered office at 5-36/8, MADHURA NAGAR COLONY, ROAD NO.1, KASHIBUGGA 1, WARANGAL, Warangal Urban, Telangana, 506006 (hereinafter to as &ldquo;Spetrol&rdquo;).<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">By visiting and/or using the Website/the Application, you agree to the terms of this Privacy Policy and it shall be deemed that you have read in full, accepted and agreed to the terms herein. Spetrol reserves the right to modify the terms contained in this Privacy Policy at any time by posting such modifications on the Website/the Application. The modified Privacy Policy would be effective from the date of modification that is posted by Spetrol on the Website/the Application. If you do not agree to any term that is contained in this Privacy Policy, please immediately cease your use of the Website/ the Application. <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">This Privacy Policy is issued pursuant to the provisions that are contained in (a) Section 43A of the Information Technology Act, 2000; (b) Regulation 4 of the Information Technology ((Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011; and (c) Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.</p>
<p class="p2">Information that is collected by Spetrol on the Website/the Application shall be used by Spetrol only for the purpose of providing the Services, and such information shall not be used, sold, transferred or rented in a manner that is not set out in this Privacy Policy. <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p4">The terms &quot;Spetrol/We&quot; / &quot;Us&quot; / &quot;Our&quot; individually and collectively refer to Spetrol and the terms &quot;You&quot; /&quot;Your&quot; / &quot;Yourself&quot; refer to the users. This Privacy Policy is a contract between You and Spetrol whose Website/Application You use or access or You otherwise deal with. This Privacy Policy shall be read together with the respective Terms and Conditions or Terms Of Use of Spetrol entity and its respective Website/Application.</p>
<p class="p3"><br></p>
<p class="p5"><span class="s3">1. PERSONAL INFORMATION THAT IS COLLECTED BY SPETROL:&nbsp;</span>Personal Information is Information collected that can be used to uniquely identify or contact You. Personal Information for the purposes of this Privacy Policy shall include, but not be limited to:</p>
<p class="p6"><br></p>
<ol class="ol1">
    <li class="li2">Name</li>
    <li class="li2">Email address</li>
    <li class="li2">Mobile/Phone number/s</li>
    <li class="li2">Address (residential/commercial)Vehicle Registration number (if any)</li>
    <li class="li2"><span class="s4">Your financial information( such as bank account information or credit card or debit card or other payment instrument details including OTP)</span></li>
    <li class="li2">Location details</li>
    <li class="li2">IP address</li>
    <li class="li2">Browser details</li>
</ol>
<p class="p2">In providing the various services that are available on the Website/the Application (the &ldquo;Services&rdquo;), Spetrol may have to collect certain personal information from you. This information would, inter alia, include the following:<span class="Apple-converted-space">&nbsp;</span></p>
<ol class="ol2">
    <li class="li2">Contact information: Spetrol may collect your name, email, mobile number, vehicle registration number, postal address, etc.<span class="Apple-converted-space">&nbsp;</span></li>
    <li class="li2">Device and Log Information. Spetrol may collect information about your device and browser. This may include device information (the type of device, operating system version, unique device identifier, browser type, and version, mobile network information) or log information (IP address, MAC address, screen resolution, and referring domain).<span class="Apple-converted-space">&nbsp;</span></li>
    <li class="li2">Payment and billing information. Spetrol may collect your billing name, billing address and payment method when you pay the Charges (as defined in the Terms of Use available here _____________) on the Website/Application. Please note that Spetrol&rsquo;s online payment partner would collect your credit/debit card number or credit/debit card expiry date or other details pertaining to your credit/debit card or bank account details.</li>
    <li class="li7"><span class="s3">Financial Information. Spetrol may&nbsp;</span><span class="s4">use other third parties such as a credit/debit card processing company, payment gateway, pre-paid cards etc. to enable you to make payments for availing services on Spetrol. When You sign up for these services, You may have the ability to save Your card details and/or UPI details for future reference and faster future payments. In such case, We may share Your relevant Personal Information as necessary for the third parties to provide such services, including your name, residence and email address. The processing of payments or authorization is solely in accordance with these third party&rsquo;s policies, terms and conditions and we are not in any manner responsible or liable to You or any third party for any delay or failure at their end in processing the payments.</span></li>
    <li class="li2">&nbsp;Other information. While you use the Website/Application, Spetrol may collect information about your IP address and the browser you are using.</li>
    <li class="li2">Advertisements. When Spetrol serves an advertisement to user&rsquo;s device, we collect information about user&rsquo;s interaction with this advertisement to help us evaluate the performance of the advertisement that we show, such as whether you clicked on it, or installed the advertised product. The third parties that serve these advertisements may also collect other information about your interaction with an advertisement. This collection and the subsequent use of this information by these third parties is subject to these third parties&apos; privacy policies over which Spetrol has no control.</li>
</ol>
<p class="p2">You acknowledge and agree that any and all information that is provided by you on the Website/Application is provided by you voluntarily and at your own risk. While Spetrol would make all efforts to ensure the safety and security of the information provided for you, it shall not be responsible for any loss or theft of data. Please note that any information that is provided by you may be retained by Spetrol for (i) providing the Services; and (ii) for meeting its obligations under applicable law. Even if you delete your User account on Website or uninstall the Application, Spetrol may have to retain the information provided by you for a short period of time until the same can be removed from its servers, and for compliance with its obligations under law (if any).</p>
<p class="p2">You shall be responsible for the accuracy of the information provided to Spetrol for the use of the Website/Application and the Services. If there is any mistake in or change to the personal information provided by you for the use of Website/Application, you shall immediately notify Spetrol at www.spetrol.in. You shall be solely liable for the consequences of providing incomplete, inaccurate or incorrect information on the Website. Where Spetrol has reasons to believe that the information provided by you on the Website/Application is incorrect or incomplete, Spetrol may restrict you from using the Website/Application by disabling your User Account.</p>
<p class="p2">If you want to discontinue the use of the Website/Application, please notify Spetrol at www.spetrol.in. Once you discontinue the use of the Website/Application, Spetrol shall retain the personal information provided by you on the Website/Application for such time period as may be mandated by applicable law.</p>
<ol class="ol3">
    <li class="li2">INFORMATION MAY BE COLLECTED IN SEVERAL WAYS:</li>
</ol>
<p class="p2">Active collection: This refers to the information that is actively provided by you while registering on the Website/Application, while placing an order for the procurement of the Product and so on etc.</p>
<p class="p2">Passive Collection: This refers to the information that is gathered by Spetrol using tracking tools like Google Analytics, Google Webmaster, browser cookies etc. for collecting information about your usage of our website. This information may be used by Spetrol for internal research to understand User demographics, interests and behaviour with a view to improve the functionality of the Website/Application. This information may include the URL of the website that you visited just before visiting the Website or which one you go to after visiting the Website or accessing the Application, your computer browser information, and your IP address.</p>
<p class="p3"><br></p>
<p class="p2">While Spetrol does not actively collect any information from persons who visit the Website, but do not register themselves on it or avail of the Services, certain portions of this Privacy Policy would be applicable to such visitors as well.</p>
<p class="p3"><br></p>
<ol class="ol3">
    <li class="li2">USE OF YOUR PERSONAL INFORMATION BY SPETROL:</li>
</ol>
<ol class="ol1">
    <li class="li2">To contact you: The contact information you provide would be used by Spetrol to contact you for the confirmation of an order or for any other Service.</li>
    <li class="li2">Purchase and delivery of services. We use your personal information to take and fulfill orders, deliver products and services, process payments, and communicate with you about orders, products and services, and promotional offers.</li>
    <li class="li2">To improve the quality of the Services: Spetrol might use your information to improve the functionality of the Services and to ensure that its quality is maintained / bettered.</li>
    <li class="li2">For security purposes: Spetrol might use the information provided by you on the Website/Application to protect our company, our customers, or our websites.</li>
    <li class="li2">For marketing purposes: Spetrol might send you information about special promotions or offers. Spetrol might also notify you of new or improved features on the Website/Application.</li>
    <li class="li2">Transactional communications: Spetrol may also contact you by way of emails or SMSs or push notifications to confirm an order, confirm the payment of Charges etc.</li>
    <li class="li2">Spetrol shall use your information only in a manner that is permitted by applicable law.</li>
</ol>
<p class="p8"><br></p>
<p class="p8"><br></p>
<ol class="ol3">
    <li class="li2">SHARING OF INFORMATION BY SPETROL WITH THIRD-PARTIES:</li>
</ol>
<ol class="ol1">
    <li class="li2">Service Providers: Spetrol shall share your information with persons who Spetrol has contracted with for assistance with performing the Services such as payment processors or transactional message processors.</li>
    <li class="li2">Compliance with law: Spetrol might share your information to respond to a court order or subpoena. Spetrol may share your information if a government agency or investigatory body requests Spetrol to do so.</li>
    <li class="li2">Mergers and Acquisitions: If Spetrol sells its business or hives of a portion of the same to a new entity, your personal information that is available on the Website/Appliation may be transferred to the new entity.</li>
    <li class="li2">Third Parties: Spetrol may share your Information with third parties under a confidentiality agreement which inter alia provides for that such third parties not disclosing the Information further unless such disclosure is for the Purpose. However, Spetrol is not responsible for any breach of security or for any actions of any third parties that receive Your Personal Information. Spetrol is not liable for any loss or injury caused to You as a result of You providing Your Personal Information to third party (including any third party websites, even if links to such third party websites are provided on the Website).<span class="Apple-converted-space">&nbsp;</span></li>
</ol>
<ol class="ol3">
    <li class="li2">SECURITY OF YOUR INFORMATION:</li>
</ol>
<ol class="ol1">
    <li class="li2">Please note that neither Spetrol nor any of its employees would have access to the password that relates to your User account on the Website/Application. You shall be solely responsible for protecting your login credentials to the Website/Application, and Spetrol shall not be liable in any manner for unauthorised use of your login credentials. You shall be liable to Spetrol for any loss suffered by Spetrol as a result of unauthorised use of your login credentials. In the event of any misuse or suspected misuse of the same, please notify Spetrol at www.spetrol.in.</li>
    <li class="li2">While Spetrol shall seek to implement the best market practices and security policies for the protection of your personal information, it shall not be liable for theft or loss of data due to unauthorized access to the User&rsquo;s electronic devices.</li>
    <li class="li2">Cookies: A cookie is a small file which asks permission to be placed on your computer&apos;s hard drive. Once you grant the permission, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Spetrol uses traffic log cookies to identify which pages are being used. This helps Spetrol analyze data about web page traffic and improve the functioning of the Website. This information is used by Spetrol only for statistical analysis and then the data is removed from the system. Please note that Spetrol does not have any control over the cookies that are placed by third party links on the Website. Spetrol does not represent to you that such third party websites have their own Privacy Policies or that the information that is provided by you to them would be secure.</li>
</ol>
<ol class="ol3">
    <li class="li2">THIRD PARTY SITES</li>
</ol>
<ol class="ol1">
    <li class="li2">If you click on one of the links to third party websites that may be available on the Website/Application, you may be taken to websites that Spetrol does not control. This Privacy Policy does not apply to the privacy practices of those websites.</li>
    <li class="li2">Spetrol shall not be liable for the misuse of your personal information by third party websites.</li>
</ol>
<ol class="ol3">
    <li class="li2">SECURITY &amp; RETENTION</li>
</ol>
<ol class="ol1">
    <li class="li2">The security of your Personal Information is important to us. Spetrol strives to ensure the security of Your Personal Information and to protect Your Personal Information against unauthorized access or unauthorized alteration, disclosure or destruction. For this purpose, Spetrol adopts internal reviews of the data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where Spetrol stores Your Personal Information. Spetrol shall adopt reasonable security practices and procedures as mandated under applicable laws for the protection of Your Information. Provided that Your right to claim damages shall be limited to the right to claim only statutory damages under Information Technology Act, 2000 and You hereby waive and release Spetrol from any claim of damages under contract or under tort.</li>
    <li class="li2">If you choose a payment gateway to complete any transaction on Website/Application then Your credit card data may be stored in compliance with industry standards/ recommended data security standard for security of financial information such as the Payment Card Industry Data Security Standard (PCI-DSS).</li>
</ol>
<p class="p3"><br></p>
<ol class="ol3">
    <li class="li2">REDRESSAL OF GRIEVANCES</li>
</ol>
<p class="p9">If a User has any questions or grievances regarding the Website/Application, the contents thereof or the Services, the User may reach out to customer support at email address ___________ (the &ldquo;Grievance Officer&rdquo;). The Grievance Officer shall address any complaint or grievance that is raised.</p>
<p class="p6"><br></p>
<ol class="ol3">
    <li class="li2">MISCELLANEOUS PROVISIONS</li>
</ol>
<ol class="ol1">
    <li class="li2">Force Majeure: Spetrol shall not responsible for any cessation, interruption or delay in the performance of its obligations hereunder due to pandemic, earthquake, flood, fire, storm, natural disaster, act of God, war, terrorism, armed conflict, labor strike, lockout, lockdown or boycott. Spetrol may at any time, in its sole discretion and without advance notice to you, cease operation of the Website and the provision of the Services.</li>
    <li class="li2">No Waiver: A delay or failure by Spetrol to exercise or enforce any right or provision of this Privacy Policy will not constitute a waiver of such right or provision.</li>
    <li class="li2">Notices: All notices given to a User by Spetrol or by a User to Spetrol or another User shall be in writing and in the English language. Such notice shall be sent by e-mail or mailed by a prepaid internationally-recognized courier service to the intended recipient at the address set out below, or any changed address that is notified by either Party:</li>
</ol>
<table cellpadding="0" cellspacing="0" class="t1">
    <tbody>
        <tr>
            <td class="td1" valign="top">
                <p class="p2">NOTICE TO SPETROL</p>
            </td>
            <td class="td1" valign="top">
                <p class="p10">ADDRESS</p>
                <p class="p11"><br></p>
                <p class="p11"><br></p>
                <p class="p11"><br></p>
                <p class="p12"><br></p>
            </td>
        </tr>
    </tbody>
</table>
<p class="p13"><br></p>
<p class="p14">Notice to User:</p>
<p class="p13"><br></p>
<p class="p14">At the email address provided by you at the time of registration on the Website/Application.</p>
<p class="p13"><br></p>
<ol class="ol1">
    <li class="li2">Applicable Law: This Privacy Policy shall be governed by and construed in accordance with the laws of the Union of India or State of Telangana.<span class="Apple-converted-space">&nbsp;</span></li>
    <li class="li2">Exclusive jurisdiction: This Privacy Policy shall be governed by and constructed according to the laws in force in India. The buyer shall hereby submit to the jurisdiction of the courts situated at Hyderabad for the purpose of actions and proceedings arising out of the Privacy Policy and the courts at Hyderabad shall have the sole jurisdiction to hear and decide such actions and proceedings.</li>
</ol>`
    return (
        <div style={{marginRight:100,marginLeft:100}}>
        {Parser().parse(policy)}
    </div>
    )
}

export default PrivacyPolicy;
